@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .fade-in {
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .truncate-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .vacation-link {
    @apply text-sm/[18px] sm:text-sm/4 cursor-pointer text-primary hover:text-strawberry-ice-cream;
  }

  .vacation-button {
    @apply cursor-pointer rounded-full bg-white py-1.5 px-6 text-sm md:text-base/5 xl:text-lg/6 font-normal text-night-sky-dark-blue-100;

    &.button-lg {
      @apply font-semibold;
    }

    &.button-dark {
      @apply bg-night-sky-dark-blue-100 text-white;
    }
  }

  .vacation-button,
  .vacation-button.button-dark {
    @apply hover:bg-strawberry-ice-cream hover:text-white;
  }
}
