// Don't change the setting in this file directly, instead check 'THEME_COLORS_LIST' in './colors.constant.ts'.
:root {
  --primary-color: 255 102 1;
  --secondary-color: 255 204 51;
  --tertiary-color: 247 153 28;
  --tertiary-layer-color: 247 153 28;
  --tertiary-selector-color: 255 204 153;
  --neutral-color: 255 242 204;
  --background-color: 255 242 204;
  --loader-color: 255 242 204;

  --primary-color-hex: #ff6601;
  --secondary-color-hex: #ffcc33;
  --tertiary-color-hex: #f7991c;
  --tertiary-layer-color-hex: #f7991c;
  --tertiary-selector-color-hex: #ffcc99;
  --neutral-color-hex: #fff2cc;
  --background-color-hex: #fff2cc;
  --loader-color-hex: #fff2cc;
}
