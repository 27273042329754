//** @link https://swiperjs.com/swiper-api#scss-styles */
@import '../../../../node_modules/swiper/swiper.scss';
@import '../../../../node_modules/swiper/modules/autoplay.scss';
@import '../../../../node_modules/swiper/modules/free-mode.scss';
@import '../../../../node_modules/swiper/modules/mousewheel.scss';
@import '../../../../node_modules/swiper/modules/thumbs.scss';
@import '../../../../node_modules/swiper/modules/pagination.scss';
@import '../../../../node_modules/swiper/modules/navigation.scss';

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-primary;
}

:root {
  --swiper-pagination-bullet-width: 24px;
  --swiper-pagination-bullet-height: 3px;
  --swiper-pagination-bullet-border-radius: 0px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: #e0e0e0;
  --swiper-navigation-color: #668c98;
}
