// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './palette.scss';

@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$YesAndTravelWebPrimary: mat.define-palette(
  $yes-and-travel-primary-palette
);
$YesAndTravelWebAccent: mat.define-palette($yes-and-travel-accent-palette);

// The warn palette is optional (defaults to red).
$YesAndTravelWeb-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$YesAndTravelWeb-theme: mat.define-light-theme(
  (
    color: (
      primary: $YesAndTravelWebPrimary,
      accent: $YesAndTravelWebAccent,
      warn: $YesAndTravelWeb-warn,
    ),
  )
);

@include mat.all-component-themes($YesAndTravelWeb-theme);

html {
  --mat-table-row-item-label-text-font: 'Poppins';
}

a[mat-raised-button] {
  padding-left: 48px;
  padding-right: 48px;
}

.mdc-button {
  --mat-mdc-button-persistent-ripple-color: none;
  font-family: Poppins;
  font-weight: 600;
  box-shadow: none !important;

  &.cdk-keyboard-focused {
    --mdc-text-button-label-text-color: rgb(252 153 173);
  }

  &.mat-strawberry-ice-cream {
    --mdc-text-button-label-text-color: rgb(252 153 173);
  }
  &.mdc-button--outlined.mat-mdc-outlined-button {
    border-color: #4f4f4f;

    .mdc-button__label {
      color: #4f4f4f;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &.mat-mdc-button-base {
    &.mat-primary {
      &:hover {
        color: rgb(var(--secondary-color) / 0.5);
        .mat-mdc-button-persistent-ripple::before {
          opacity: 0;
        }
      }

      &.mdc-button--raised.mat-mdc-raised-button {
        .mdc-button__label {
          color: white;
        }

        &:hover {
          background-color: rgb(252 153 173);
        }
      }
    }
  }

  &.mat-accent {
    &:hover {
      .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }
    }

    &.mdc-button--raised.mat-mdc-raised-button {
      border: rgb(var(--primary-color)) 1px solid;

      .mdc-button__label {
        color: rgb(var(--primary-color));
      }

      &:hover {
        background-color: #fef5e8;
        border: rgb(var(--primary-color)) 1px solid;

        .mdc-button__label {
          color: rgb(var(--primary-color));
        }
      }

      &[disabled='true'] {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: #f2f2f2;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.mat-mdc-menu-item {
  --mat-menu-item-hover-state-layer-color: none;
  &.cdk-program-focused {
    --mat-menu-item-focus-state-layer-color: none;
  }
}

.mat-mdc-progress-spinner.mdc-circular-progress {
  --mdc-circular-progress-active-indicator-color: rgb(var(--loader-color));
}

.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell {
  background-color: #f9f9f9;
  color: #828282;
  border-color: #e1e3ea;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  height: 50px;
}

.mat-mdc-checkbox .mdc-form-field {
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-hover-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-selected-focus-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-selected-icon-color: rgb(var(--tertiary-selector-color));
  --mdc-checkbox-disabled-selected-icon-color: rgba(
    var(--tertiary-selector-color) 70
  );
  --mdc-checkbox-unselected-focus-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-focus-state-layer-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-hover-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-hover-state-layer-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-pressed-icon-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-unselected-pressed-state-layer-color: rgb(
    var(--tertiary-selector-color)
  );
  --mdc-checkbox-disabled-unselected-icon-color: rgba(
    var(--tertiary-selector-color) 70
  );
  display: flex;

  .mdc-checkbox__background {
    @apply border-[3px];
  }

  .mdc-label {
    @apply flex flex-1 overflow-hidden;
    > div {
      @apply w-full;
    }
  }
}

.cdk-overlay-pane {
  .mdc-tooltip .mdc-tooltip__surface {
    @apply bg-gray-6 text-xs font-light text-gray-1;
  }

  &.root-loader-panel {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      background: none;
      box-shadow: none;
    }
  }
}
