@import './theme.scss';
@import './material.scss';
@import './tailwind.scss';
@import './swiper.scss';

html,
body {
  @apply m-0 box-border h-full w-full scroll-auto p-0 text-gray;
  font-family: 'Poppins', sans-serif;

  input,
  textarea {
    @apply px-2;
  }

  h1 {
    @apply text-[32px] leading-10;
  }

  h2 {
    @apply text-xl leading-6;
  }

  h3 {
    @apply text-base leading-5;
  }
}